var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Default spinner styling" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeSpinner) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("The default overlay content is a ")]),
        _c("code", [_vm._v("<b-spinner>")]),
        _c("span", [_vm._v(" of type ")]),
        _c("code", [_vm._v("'border'")]),
        _c("span", [
          _vm._v(
            ". You can control the appearance of the spinner via the following props:"
          )
        ])
      ]),
      _c(
        "b-overlay",
        {
          attrs: {
            show: "",
            "spinner-variant": "primary",
            "spinner-type": "grow",
            "spinner-small": "",
            rounded: "sm"
          }
        },
        [
          _c(
            "b-card",
            {
              staticClass: "mb-0",
              attrs: { title: "Card with spinner style", "aria-hidden": "true" }
            },
            [
              _c("b-card-text", [
                _vm._v(
                  " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. "
                )
              ]),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(255, 255, 255, 0.15)",
                      expression: "'rgba(255, 255, 255, 0.15)'",
                      modifiers: { "400": true }
                    }
                  ],
                  attrs: { disabled: "", variant: "primary" }
                },
                [_vm._v(" Button ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }