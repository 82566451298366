var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Custom overlay content" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeCustomContent) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [
          _vm._v(
            "Place custom content in the overlay (replacing the default spinner) via the optionally scoped slot "
          )
        ]),
        _c("code", [_vm._v("overlay")])
      ]),
      _c(
        "div",
        [
          _c(
            "b-overlay",
            {
              attrs: { show: _vm.show, rounded: "sm" },
              on: { shown: _vm.onShown, hidden: _vm.onHidden },
              scopedSlots: _vm._u([
                {
                  key: "overlay",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _c("feather-icon", {
                            attrs: { icon: "ClockIcon", size: "24" }
                          }),
                          _c("b-card-text", { attrs: { id: "cancel-label" } }, [
                            _vm._v(" Please wait... ")
                          ]),
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "ripple",
                                  rawName: "v-ripple.400",
                                  value: "rgba(255, 255, 255, 0.15)",
                                  expression: "'rgba(255, 255, 255, 0.15)'",
                                  modifiers: { "400": true }
                                }
                              ],
                              ref: "cancel",
                              attrs: {
                                variant: "outline-danger",
                                size: "sm",
                                "aria-describedby": "cancel-label"
                              },
                              on: {
                                click: function($event) {
                                  _vm.show = false
                                }
                              }
                            },
                            [_vm._v(" Cancel ")]
                          )
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "div",
                [
                  _c("h6", [_vm._v("Card with custom overlay content")]),
                  _c("b-card-text", [
                    _vm._v(
                      "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                    )
                  ]),
                  _c("b-card-text", [
                    _vm._v("Click the button to toggle the overlay:")
                  ]),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "ripple",
                          rawName: "v-ripple.400",
                          value: "rgba(255, 255, 255, 0.15)",
                          expression: "'rgba(255, 255, 255, 0.15)'",
                          modifiers: { "400": true }
                        }
                      ],
                      ref: "show",
                      attrs: { disabled: _vm.show, variant: "primary" },
                      on: {
                        click: function($event) {
                          _vm.show = true
                        }
                      }
                    },
                    [_vm._v(" Show overlay ")]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }