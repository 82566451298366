import { render, staticRenderFns } from "./OverlaySpinner.vue?vue&type=template&id=2630f152&"
import script from "./OverlaySpinner.vue?vue&type=script&lang=js&"
export * from "./OverlaySpinner.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/jasontaylor/Sites/Cancom_Secure_Portal_2021/cancom-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2630f152')) {
      api.createRecord('2630f152', component.options)
    } else {
      api.reload('2630f152', component.options)
    }
    module.hot.accept("./OverlaySpinner.vue?vue&type=template&id=2630f152&", function () {
      api.rerender('2630f152', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/components/overlay/OverlaySpinner.vue"
export default component.exports