var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Overlay backdrop color" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeBackdrop) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [
          _vm._v("You can control the backdrop background color via the ")
        ]),
        _c("code", [_vm._v("variant")]),
        _c("span", [
          _vm._v(" prop. The variant is translated into one of Bootstrap's ")
        ]),
        _c("code", [_vm._v("background variant utility classes")]),
        _c("span", [_vm._v(". Control the opacity of the backdrop via the")]),
        _c("code", [_vm._v("opacity")]),
        _c("span", [_vm._v(" prop (opacity values can range from ")]),
        _c("code", [_vm._v("0")]),
        _c("span", [_vm._v(" to ")]),
        _c("code", [_vm._v("1")]),
        _c("span", [
          _vm._v("). And background blurring can be controlled via the ")
        ]),
        _c("code", [_vm._v("blur")]),
        _c("span", [_vm._v(" prop.")])
      ]),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { lg: "6", "aria-controls": "overlay-background" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Variant",
                    "label-for": "bg-variant",
                    "label-cols-sm": "4",
                    "label-cols-lg": "12"
                  }
                },
                [
                  _c("b-form-select", {
                    attrs: { id: "bg-variant", options: _vm.variants },
                    model: {
                      value: _vm.variant,
                      callback: function($$v) {
                        _vm.variant = $$v
                      },
                      expression: "variant"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Opacity",
                    "label-for": "bg-opacity",
                    "label-cols-sm": "4",
                    "label-cols-lg": "12"
                  }
                },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "bg-opacity",
                          type: "range",
                          number: "",
                          min: "0",
                          max: "1",
                          step: "0.01"
                        },
                        model: {
                          value: _vm.opacity,
                          callback: function($$v) {
                            _vm.opacity = $$v
                          },
                          expression: "opacity"
                        }
                      }),
                      _c(
                        "b-input-group-append",
                        {
                          staticClass: "text-monospace",
                          attrs: { "is-text": "" }
                        },
                        [_vm._v(" " + _vm._s(_vm.opacity.toFixed(2)) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  staticClass: "mb-2 mb-lg-0",
                  attrs: {
                    label: "Blur",
                    "label-for": "bg-blur",
                    "label-cols-sm": "4",
                    "label-cols-lg": "12"
                  }
                },
                [
                  _c("b-form-select", {
                    attrs: { id: "bg-blur", options: _vm.blurs },
                    model: {
                      value: _vm.blur,
                      callback: function($$v) {
                        _vm.blur = $$v
                      },
                      expression: "blur"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { lg: "6" } },
            [
              _c(
                "b-overlay",
                {
                  attrs: {
                    id: "overlay-background",
                    show: "",
                    variant: _vm.variant,
                    opacity: _vm.opacity,
                    blur: _vm.blur,
                    rounded: "sm"
                  }
                },
                [
                  _c(
                    "b-card",
                    {
                      staticClass: "mb-0",
                      attrs: {
                        title: "Card with overlay",
                        "aria-hidden": "true"
                      }
                    },
                    [
                      _c("b-card-text", [
                        _vm._v(
                          " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. "
                        )
                      ]),
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "ripple",
                              rawName: "v-ripple.400",
                              value: "rgba(255, 255, 255, 0.15)",
                              expression: "'rgba(255, 255, 255, 0.15)'",
                              modifiers: { "400": true }
                            }
                          ],
                          attrs: { disabled: "", variant: "primary" }
                        },
                        [_vm._v(" Button ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }