var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Form confirmation prompt and upload status" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeForm) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [
          _vm._v("This example is a bit more complex, but shows the use of ")
        ]),
        _c("code", [_vm._v("no-wrap")]),
        _c("span", [_vm._v(" , and using the ")]),
        _c("code", [_vm._v("overlay")]),
        _c("span", [
          _vm._v(
            " slot to present the user with a prompt dialog, and once confirmed it shows a uploading status indicator. This example also demonstrates additional accessibility markup. "
          )
        ])
      ]),
      _c(
        "div",
        [
          _c(
            "b-form",
            {
              staticClass: "position-relative",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.onSubmit($event)
                }
              }
            },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Name",
                    "label-for": "form-name",
                    "label-cols-lg": "2"
                  }
                },
                [
                  _c(
                    "b-input-group",
                    [
                      _c(
                        "b-input-group-prepend",
                        { attrs: { "is-text": "" } },
                        [_c("feather-icon", { attrs: { icon: "UserIcon" } })],
                        1
                      ),
                      _c("b-form-input", {
                        attrs: { id: "form-name", disabled: _vm.busy }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Email",
                    "label-for": "form-mail",
                    "label-cols-lg": "2"
                  }
                },
                [
                  _c(
                    "b-input-group",
                    [
                      _c(
                        "b-input-group-prepend",
                        { attrs: { "is-text": "" } },
                        [_c("feather-icon", { attrs: { icon: "MailIcon" } })],
                        1
                      ),
                      _c("b-form-input", {
                        attrs: {
                          id: "form-email",
                          type: "email",
                          disabled: _vm.busy
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Image",
                    "label-for": "form-image",
                    "label-cols-lg": "2"
                  }
                },
                [
                  _c(
                    "b-input-group",
                    [
                      _c(
                        "b-input-group-prepend",
                        { attrs: { "is-text": "" } },
                        [_c("feather-icon", { attrs: { icon: "ImageIcon" } })],
                        1
                      ),
                      _c("b-form-file", {
                        attrs: {
                          id: "form-image",
                          disabled: _vm.busy,
                          accept: "image/*"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex justify-content-center" },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "ripple",
                          rawName: "v-ripple.400",
                          value: "rgba(255, 255, 255, 0.15)",
                          expression: "'rgba(255, 255, 255, 0.15)'",
                          modifiers: { "400": true }
                        }
                      ],
                      ref: "submit",
                      attrs: {
                        variant: "primary",
                        type: "submit",
                        disabled: _vm.busy
                      }
                    },
                    [_vm._v(" Submit ")]
                  )
                ],
                1
              ),
              _c("b-overlay", {
                attrs: { show: _vm.busy, "no-wrap": "" },
                on: { shown: _vm.onShown, hidden: _vm.onHidden },
                scopedSlots: _vm._u([
                  {
                    key: "overlay",
                    fn: function() {
                      return [
                        _vm.processing
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "text-center p-3 bg-primary text-light rounded"
                              },
                              [
                                _c("feather-icon", {
                                  attrs: { icon: "UploadCloudIcon", size: "20" }
                                }),
                                _c("div", { staticClass: "mb-2" }, [
                                  _vm._v(" Processing... ")
                                ]),
                                _c("b-progress", {
                                  staticClass: "mx-n3",
                                  attrs: {
                                    min: "1",
                                    max: "20",
                                    value: _vm.counter,
                                    variant: "success",
                                    height: "6px"
                                  }
                                })
                              ],
                              1
                            )
                          : _c(
                              "div",
                              {
                                ref: "dialog",
                                staticClass: "text-center p-3",
                                attrs: {
                                  tabindex: "-1",
                                  role: "dialog",
                                  "aria-modal": "false",
                                  "aria-labelledby": "form-confirm-label"
                                }
                              },
                              [
                                _c(
                                  "b-card-text",
                                  { staticClass: "font-weight-bolder" },
                                  [_vm._v(" Are you sure? ")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "ripple",
                                            rawName: "v-ripple.400",
                                            value: "rgba(234, 84, 85, 0.15)",
                                            expression:
                                              "'rgba(234, 84, 85, 0.15)'",
                                            modifiers: { "400": true }
                                          }
                                        ],
                                        staticClass: "mr-3",
                                        attrs: { variant: "outline-danger" },
                                        on: { click: _vm.onCancel }
                                      },
                                      [_vm._v(" Cancel ")]
                                    ),
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "ripple",
                                            rawName: "v-ripple.400",
                                            value: "rgba(40, 199, 111, 0.15)",
                                            expression:
                                              "'rgba(40, 199, 111, 0.15)'",
                                            modifiers: { "400": true }
                                          }
                                        ],
                                        attrs: { variant: "outline-success" },
                                        on: { click: _vm.onOK }
                                      },
                                      [_vm._v(" OK ")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }